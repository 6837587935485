import http from 'axios'
let baseURL
    /* if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        baseURL = 'http://192.168.1.49:8090'
    } else {
        baseURL = 'http://duberick.dyndns.org:8090'
    } */
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseURL = 'http://192.168.2.200:8080'
} else {
    //baseURL = 'http://duberick.dyndns.org:8080'
    baseURL = 'https://alarmedub.eu.ngrok.io'
}
export const axios = http.create({
    baseURL: baseURL
})